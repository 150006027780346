/**
=========================================================
* EYouth React - v2.1.0
=========================================================

* Product Page: https://eyouthlearning.com//product/material-dashboard-react
* Copyright 2022 EYouth (https://eyouthlearning.com/)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// EYouth React components
import MDBox from "components/MDBox";

// EYouth React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";

// Overview page components
// import Header from "layouts/profile/components/Header";
// import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
// Images
import { useDispatchRequest } from "@redux-requests/react";
import { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import { fetchApplicationDetails } from "redux/application/application.actions";
import { useParams } from "react-router-dom";

function ApplicationDetails() {
  const [applicationDetails, setApplicationDetails] = useState({
    birth_date: "dT00",
    languages: {},
  });
  const { applicationId } = useParams();
  const [loading, setLoading] = useState(false);
  const dispatchRequest = useDispatchRequest();
  useEffect(() => {
    setLoading(true);

    dispatchRequest(fetchApplicationDetails(applicationId))
      .then(({ data }) => {
        setLoading(false);
        setApplicationDetails(data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      {loading && <LinearProgress />}
      {/* <Header> */}
      <MDBox mt={5} mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} xl={4}>
            <ProfileInfoCard
              title="applicant informations"
              //   description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
              info={{
                createdAt: applicationDetails.createdAt,
                fullName: applicationDetails.name,
                mobile: applicationDetails.phone,
                email: applicationDetails.email,
                formName: applicationDetails.form_name,
                linkedinURL: applicationDetails.linkedIn_url,
                jobTitle: applicationDetails.job_name,
                // companyName: applicationDetails.company_name,
                jobName: applicationDetails.job_name,
                message: applicationDetails.message,
              }}
              shadow={false}
            />
          </Grid>
        </Grid>
      </MDBox>
      {/* </Header> */}
      <Footer />
    </DashboardLayout>
  );
}

export default ApplicationDetails;
