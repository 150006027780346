/**
=========================================================
* EYouth React - v2.1.0
=========================================================

* Product Page: https://eyouthlearning.com//product/material-dashboard-react
* Copyright 2022 EYouth (https://eyouthlearning.com/)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// EYouth React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// EYouth React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import applicationsTableData from "layouts/application/data/applicationTableData";
import { useDispatchRequest } from "@redux-requests/react";
import { useEffect, useState } from "react";
import { fetchApplications, fetchForms } from "redux/application/application.actions";
import { ExportJsonCsv } from "react-export-json-csv";
import MDButton from "components/MDButton";
import { BACKEND_HOST } from "config";
// import { useLocation } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

// import { useDispatch } from "react-redux";

function ApplicationsTable() {
  const dispatchRequest = useDispatchRequest();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [forms, setForms] = useState([]);
  const [formName, setFormName] = useState("all");
  useEffect(() => {
    dispatchRequest(fetchApplications(formName)).then(({ data }) => {
      const applicationsDataTable = applicationsTableData(data);
      setApplicationData(data);
      setColumns(applicationsDataTable.columns);
      setRows(applicationsDataTable.rows);
    });
    console.log("FormName", formName);
  }, [formName]);

  useEffect(() => {
    dispatchRequest(fetchForms()).then(({ data }) => {
      // eslint-disable-next-line no-console
      console.log("Forms", data);
      setForms(["all", ...data]);
    });
  }, []);
  const getLanguageHeaders = () => {
    const headers = new Set();
    if (applicationData) {
      applicationData.forEach((application) => {
        if (application.languages) {
          Object.keys(application.languages).forEach((lang) => headers.add(lang));
        }
      });
    }
    return headers;
  };

  const getCSVHeaders = () => {
    const headers = [
      ...Object.keys(applicationData[0])
        .filter((k) => k !== "languages")
        .map((k) => ({ key: k, name: k })),
      ...Array.from(getLanguageHeaders()).map((lang) => ({ key: lang, name: lang })),
    ];
    return headers;
  };

  // const { columns, rows } = authorsTableData();
  // const dispatch = useDispatch();
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <MDTypography variant="h6" color="white">
                  Applications Table
                </MDTypography>
                {applicationData.length > 0 && (
                  <ExportJsonCsv
                    style={{
                      border: "none",
                      background: "transparent",
                    }}
                    headers={getCSVHeaders()}
                    items={applicationData.map((app) => {
                      const result = {
                        ...app,
                        passport_frontpage: `${BACKEND_HOST}${app.passport_frontpage}`,
                        passport_backpage: `${BACKEND_HOST}${app.passport_backpage}`,
                        personal_id_front: `${BACKEND_HOST}${app.personal_id_front}`,
                        personal_id_back: `${BACKEND_HOST}${app.personal_id_back}`,
                        visa: `${BACKEND_HOST}${app.visa}`,
                        cv: `${BACKEND_HOST}${app.cv}`,
                        attachment_supporting_documents: `${BACKEND_HOST}${app.attachment_supporting_documents}`,
                        ...app.languages,
                      };
                      delete result.languages;

                      return result;
                    })}
                  >
                    <MDButton var>Export</MDButton>
                  </ExportJsonCsv>
                )}
              </MDBox>
              <MDBox pt={3}>
                {forms !== undefined && (
                  <MDBox m="auto" sx={{ justifyContent: "center", display: "flex" }}>
                    <FormControl sx={{ width: "95%", margin: "auto", height: "40px" }}>
                      <InputLabel id="demo-simple-select-label">Age</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formName}
                        label="Forms"
                        onChange={(event) => {
                          console.log("select Value", event.target.value);
                          setFormName(event.target.value);
                        }}
                        sx={{
                          height: "40px",
                        }}
                      >
                        {forms.map((form) => (
                          <MenuItem
                            sx={{
                              height: "40px",
                            }}
                            value={form}
                          >
                            {form}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </MDBox>
                )}
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ApplicationsTable;
