/* eslint-disable import/prefer-default-export */
import Utils from "redux/utils";
import ApplicationsActionTypes from "./application.types";

export const fetchApplications = (formName) => ({
  type: ApplicationsActionTypes.FETCH_APPLICATIONS,

  request: { method: "get", url: `/v1/eyouth-applicants/applications/${formName}` },
  meta: {
    onRequest: Utils.onRequest,
    getData: (data) => data,
  },
});

export const fetchForms = () => ({
  type: ApplicationsActionTypes.FETCH_FORMS,

  request: { method: "get", url: `/v1/eyouth-applicants/forms` },
  meta: {
    onRequest: Utils.onRequest,
    getData: (data) => data,
  },
});

export const fetchApplicationDetails = (id) => ({
  type: ApplicationsActionTypes.FETCH_APPLICATION_DETAILS,
  request: { method: "get", url: `/v1/eyouth-applicants/application/${id}` },
  meta: {
    onRequest: Utils.onRequest,
    getData: (data) => ({ ...data }),
  },
});
