export const BACKEND_HOST = "https://forms.eyouthlearning.com";
export const MARKETING_FORMS = [
  {
    name: "Advanced Hr Course",
    key: "advanced-hr-course-form-registration",
  },
  {
    name: "Start Summer Program 2",
    key: "start-summer-program-2",
  },
  {
    name: "Sales Techniques Course",
    key: "sales-techniques-course-form-registration/",
  },
  {
    name: "Marketing Management",
    key: "marketing-management-registration-form",
  },
  {
    name: "Copywriting Course Form Registration",
    key: "copywriting-course-form-registration",
  },
  {
    name: "Content Writing",
    key: "content-writing-registration-form",
  },
  {
    name: "Business English",
    key: "business-english-registration-form",
  },
  {
    name: "Business Developmemt",
    key: "business-developmemt-course-form-registration",
  },
  {
    name: "Webdevelopment Landing",
    key: "webdevelopment-landing-page-form",
  },
  {
    name: "Instructor business",
    key: "instructor-business-finance-form",
  },
  {
    name: "Sales Landing",
    key: "sales-landing-page-form",
  },
  {
    name: "Personal Development",
    key: "personal-development-landing-page",
  },
  {
    name: "Office Landing",
    key: "office-landing-page-form",
  },
  {
    name: "Marketing For Beginners",
    key: "marketing-for-beginners-landing-page-form",
  },
  {
    name: "Supply Chain",
    key: "supply-chain-bundle-form",
  },
  {
    name: "Human Resources Bundle",
    key: "human-resources-bundle-landing-page-form",
  },
  {
    name: "Digital Marketing",
    key: "digital-marketing-landing-page-form",
  },
  {
    name: "Entrepreneurship",
    key: "entrepreneurship-landing-page-form",
  },
];
