/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* EYouth React - v2.1.0
=========================================================

* Product Page: https://eyouthlearning.com//product/material-dashboard-react
* Copyright 2022 EYouth (https://eyouthlearning.com/)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// EYouth React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// Images
import team2 from "assets/images/profile_placeholder.jpg";

export default function data(applications) {
  const Author = ({ id, image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography
          component="a"
          href={`/applications/${id}`}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  // const Job = ({ title, description }) => (
  //   <MDBox lineHeight={1} textAlign="left">
  //     <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
  //       {title}
  //     </MDTypography>
  //     <MDTypography variant="caption">{description}</MDTypography>
  //   </MDBox>
  // );

  return {
    columns: [
      {
        Header: "#",
        id: "row",
        maxWidth: 50,
        filterable: false,
        Cell: ({ row }) => <div>{row.index + 1}</div>,
      },
      { Header: "Applicant", accessor: "applicant", width: "30%", align: "left" },
      { Header: "Mobile phone", accessor: "phone", align: "center" },
      { Header: "Form name", accessor: "formName", align: "center" },
      { Header: "createdAt", accessor: "createdAt", align: "center" },
    ],

    rows: applications.reverse().map((row) => ({
      applicant: <Author id={row.id} image={team2} name={row.name} email={row.email} />,
      phone: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {row.phone}
        </MDTypography>
      ),
      formName: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {row.form_name}
        </MDTypography>
      ),
      createdAt: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {row.createdAt}
        </MDTypography>
      ),
    })),
  };
}
